/** @jsx jsx */
import { jsx, Text } from "theme-ui"

import { graphql } from "gatsby"

import TopNavigation from "../components/TopNavigation"
import Layout from "../layout"

function LegalPage(props) {
  const { data } = props
  const page = data.legalPage

  const date = new Date(page.updated_at).toLocaleDateString()
  return (
    <Layout title={page.title}>
      <TopNavigation />
      <Text mt={4} sx={{ fontSize: 14 }}>{`Last revised on ${date}`}</Text>
      <section dangerouslySetInnerHTML={{ __html: page.html }} />
    </Layout>
  )
}

export default LegalPage

export const postQuery = graphql`
  query($id: String!) {
    legalPage: ghostPage(id: { eq: $id }) {
      slug
      title
      id
      html
      updated_at
    }
  }
`
